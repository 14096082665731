import mixpanel from "mixpanel-browser";
import firebase from "firebase/compat/app";
import "firebase/compat/analytics";

export const initializeTelemetry = () => {
  mixpanel.init("7b8b59d50570502c7e921642ad5a0706");
};

export enum MarketingEvent {
  emailSubscribed = "email subscribed",
}

export enum AuthEvent {
  signup = "signup",
  login = "login",
  logout = "logout",

  resetPassword = "reset password",
  changePassword = "change password",
  changeEmail = "change email",
}

export enum PaymentEvent {
  success = "success",
  error = "error",
}

export enum FeedbackEvent {
  opened = "feedback opened",
  submitted = "feedback submitted",
  error = "feedback error",
}

export enum DashboardEvent {
  publish = "publish",
  generateLivePreviewCrops = "generate live preview crops",
  livePreview = "live preview",
}

export enum SetupEvent {
  subscribeClicked = "setup subscribe clicked",
  editWebsiteClicked = "setup edit website clicked",
  finishEditsClicked = "setup finish edits clicked",

  makeWebsiteCardClicked = "setup editor card clicked",
}

export type Event =
  | MarketingEvent
  | AuthEvent
  | FeedbackEvent
  | DashboardEvent
  | SetupEvent
  | PaymentEvent;

export const track = (name: Event, properties?: Record<string, unknown>) => {
  mixpanel.track(name, {...properties, pathname: window.location.pathname});
  firebase.analytics().logEvent(name as string, {
    ...properties,
    pathname: window.location.pathname,
  });
};

export type TrackStatusFunction = (
  name: Event,
  status: "attempt" | "success" | "error" | "skipped",
  properties?: Record<string, unknown>
) => void;

export const trackStatus: TrackStatusFunction = (
  name: Event,
  status: "attempt" | "success" | "error" | "skipped",
  properties: Record<string, unknown> = {}
) => track(name, {...properties, status});

export const identify = (userID: string) => {
  mixpanel.identify(userID);
  firebase.analytics().setUserId(userID);
};

export const trackPayingUser = (hasActiveSubscription: boolean) => {
  firebase.analytics().setUserProperties({hasActiveSubscription});
};

export const resetIdentity = () => {
  track(AuthEvent.logout);
  mixpanel.reset();
};
