import {StyleSheetManager} from "styled-components";
import isPropValid from "@emotion/is-prop-valid";
import {Analytics} from "@vercel/analytics/react";

import initAuth from "utils/init-auth";
import {initializeTelemetry} from "utils/telemetry";

import "styles/globals.css";

initAuth();
initializeTelemetry();

const MyApp = props => {
  // Workaround for https://github.com/zeit/next.js/issues/8592
  const {Component, pageProps, err} = props;
  const modifiedPageProps = {...pageProps, err};

  return (
    <StyleSheetManager shouldForwardProp={isPropValid}>
      <Component {...modifiedPageProps} />
      <Analytics />
    </StyleSheetManager>
  );
};

export default MyApp;
